import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2 style={{ marginTop: "30px" }}>Produtos</h2>
        </div>
        <ImageList style={{ justifyContent: "center" }} cols={3}>
          {itemData.map((item) => (
            <ImageListItem key={item.img} style={{ marginBottom: "35px" }}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
              <div className="caption">
                <h4>{item.title}</h4>
                <p>{item.author}</p>
              </div>
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </div>
  );
};

const itemData = [
  {
    img: "img/gaxeta_teflon_puro_seco.png",
    title: "Gaxetas de Teflon Puro Seco",
    author: "@gaxetas",
  },
  {
    img: "img/gaxeta_teflon_lubrificada.png",
    title: "Gaxetas de Teflon Lubrificadas",
    author: "@gaxetas",
  },
  {
    img: "img/gaxeta_teflon_grafitada_canto_aramida.png",
    title: "Gaxetas de Teflon Grafitado (Canto de Aramida - Kevlar)",
    author: "@gaxetas",
  },
  {
    img: "img/gaxeta_teflon_branco_canto_aramida.png",
    title: "Gaxetas de Teflon Branco (Canto de Aramida)",
    author: "@gaxetas",
  },
  {
    img: "img/gaxeta_aramida_grafitada.png",
    title: "Gaxetas de Aramida Grafitada",
    author: "@gaxetas",
  },
  {
    img: "img/gaxeta_aramida_teflonada.png",
    title: "Gaxetas de Aramida Teflonada",
    author: "@gaxetas",
  },
  {
    img: "img/gaxeta_fibra_carbono_grafitada.png",
    title: "Gaxetas de Fibra de Carbono Grafitada",
    author: "@gaxetas",
  },
  {
    img: "img/gaxeta_fibra_carbono_grafite.png",
    title: "Gaxetas de Fibra de Carbono e Grafite",
    author: "@gaxetas",
  },
  {
    img: "img/gaxeta_fibra_sintetica_grafitada.png",
    title: "Gaxetas Fibra Sintética Grafitada",
    author: "@gaxetas",
  },
  {
    img: "img/gaxeta_fibra_sintetica_teflonada.png",
    title: "Gaxetas de Fibra Sintética Teflonada",
    author: "@gaxetas",
  },
  {
    img: "img/gaxeta-ensebada.jpeg",
    title: "Gaxetas de Algodão",
    author: "@gaxetas",
  },
  {
    img: "img/gaxeta_algodao_grafitada.png",
    title: "Gaxetas de Algodão Grafitada",
    author: "@gaxetas",
  },
  {
    img: "img/gaxeta_fibra_vidro.png",
    title: "Gaxetas de Fibra de Vidro",
    author: "@gaxetas",
  },
  {
    img: "img/fita_teflon_expandido_autoadesivo.png",
    title: "Fita Teflon Expandido Autoadesivo",
    author: "@fitas",
  },
  {
    img: "img/fita_aramida.png",
    title: "Fita de Fibra de Aramida",
    author: "@fitas",
  },
  {
    img: "img/fita_isolacao_termica_fibra_vidro.png",
    title: "Fita de Isolação Térmica de Fibra de Vidro",
    author: "@fitas",
  },
  {
    img: "img/perfil-silicone.webp",
    title: "Perfis de Silicone",
    author: "@perfis",
  },
  {
    img: "img/pecas-silicone.jpeg",
    title: "Peças Técnicas de Silicone",
    author: "@peças",
  },
  {
    img: "img/pecas_perfis_borracha.png",
    title: "Peças e Perfis de Borracha",
    author: "@peças @perfis",
  },
  {
    img: "img/perfil_borracha_esponjosa.jpeg",
    title: "Perfis de Borracha Esponjosa",
    author: "@perfis",
  },
  {
    img: "img/lencois_borracha.png",
    title: "Lençois de Borracha",
    author: "@lençois",
  },
  {
    img: "img/tubos_mangueiras.png",
    title: "Mangueiras de Silicone",
    author: "@mangueiras",
  },
  {
    img: "img/mangueira_succao_descarga.png",
    title: "Mangueiras de Sucção e Descarga",
    author: "@mangueiras",
  },
  {
    img: "img/retentores.jpeg",
    title: "Retentores",
    author: "@retentores",
  },
  {
    img: "img/papelao_hidraulico.png",
    title: "Papelão Hidraulico",
    author: "@papelaohidraulico",
  },
  {
    img: "img/placas_tarugos_teflon.png",
    title: "Placas e Tarugos de Teflon",
    author: "@placas @tarugosdeteflon",
  },
];
