import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Gallery } from "./components/gallery";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <Gallery data={landingPageData.Gallery} />
      <Contact data={landingPageData.Contact} />

      <a
        href="https://api.whatsapp.com/send?phone=5513955380170"
        className="whatsapp-button"
        target="_blank"
        rel="noreferrer"
        style={{ position: "fixed", right: "30px", bottom: "30px" }}
      >
        <img
          src="https://i.ibb.co/VgSspjY/whatsapp-button.png"
          alt="botão whatsapp"
        />
      </a>
    </div>
  );
};

export default App;
