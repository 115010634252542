export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img
              src="img/industria.jpeg"
              className="img-responsive"
              alt=""
              style={{ marginTop: "30px" }}
            />{" "}
            {/* <ImgLogo src="img/logo_paiva.png" alt="" /> */}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2 style={{ marginTop: "30px" }}>Sobre</h2>
              <p>
                A Paiva Borrachas é uma empresa especializada em Soluções para
                Vedações de Máquinas e Equipamentos.
              </p>
              <p>
                Com mais de 20 anos de mercado, conta com ferramentaria própria,
                equipe especializada e processos padronizados, o que a capacita
                para desenvolver peças técnicas e perfis de borracha conforme
                amostra ou desenho.
              </p>
              <p>
                A Paiva Borracha tem como seus principais valores o dinamismo, a
                transparência, a eficiência, a qualidade e o comprometimento.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
